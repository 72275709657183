/* Gallery.css */

/* Increase font size for gallery title */
h2 {
  font-size: 36px;
  text-transform: uppercase;
  color: #fff; /* Adjust text color */
  margin-bottom: 35px; /* Add margin for spacing */
}

.gallery-container {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 20px;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the items horizontally */
  gap: 10px;
  padding: 0 50px; /* Add padding to the left and right */
}

.gallery-item {
  flex: 1 0 200px; /* Flex properties for a flexible grid with a max-width of 200px */
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

/* .gallery-item:hover {
  transform: scale(1.05);
} */

.gallery-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
}

/* .gallery-item:hover img {
  transform: scale(1.2);
} */
